.navbar{
    height: 50px;
    display: flex;
    border-bottom: 0.7px solid #c4c4c4;
    position: sticky;
    top: 0;
    z-index: 10;
    overflow: hidden;
    background-color: #fff;
    scroll-behavior: smooth;

    .wrapper{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .search{
            margin-left: 15px;
            display: flex;
            align-items: center;
            border: 1px solid #393E46;
            padding: 3px;
            border-radius: 9px;

            input{
                border: none;
                outline: none;
                background: transparent;
            }
        }

        .items{
            display: flex;
            align-items: center;

            .iten{
                display: flex;
                align-items: center;
                margin-right: 20px;
            }
        }
    }
}