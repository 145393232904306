.sidebar{
    flex:1;
    color: #1E2022;
    //background-color: #1E2022;
    border-right: 2px solid #dbdbdb;
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: 10;
    overflow: hidden;
    scroll-behavior: smooth;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #393E46;
        .logo{
            font-size: 30px;
            font-weight: bold;
        }
    }

    .center{
        padding-left: 10px;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-weight: bold;
                font-size: 15px;
                margin-top: 30px;
                margin-bottom: 10px;
                color: #888;
            }

            li{
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                padding: 10px;
                cursor: pointer;
                font-size: 20px;

                &:hover{
                    background-color: #393E46;
                    color: white;
                }

                span{
                    font-weight: 600;
                    margin-left: 15px;
                }

                .icon{
                    font-size: 30px;;
                }
            }
        }
    }

    hr{
        height: 0;
        border: 0.7px solid #c4c4c4;
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 2px solid #393E46;
            cursor: pointer;
            margin: 5px;
            margin-left: 12px;

            &:nth-child(1){
                background-color: #c4c4c4;
            }
            &:nth-child(2){
                background-color: #1E2022;
            }
        }
    }
}